






























































































































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import { formatDate } from "@/lib/date";
import { prepareMoney } from "@/assets/scripts/utils";
import { DocumentsByContract } from "@/models/contract";
import XDataTable from "../hoc/Table.vue";
import XDropdownWithDatePicker from "../hoc/DropdownWithDatePicker.vue";
import XDropdownWithRadioButtons from "../hoc/DropdownWithRadioButtons.vue";
import ActionDropdown from "../ActionDropdown.vue";
import XButton from "../SimpleButton.vue";

@Component({
  components: {
    XDataTable,
    XDropdownWithDatePicker,
    XDropdownWithRadioButtons,
    ActionDropdown,
    XButton,
  },
  computed: {
    ...mapState({ appEnvironment: "appEnvironment" }),
    ...mapGetters({
      contractId: "contract/id",
      contractInactive: "contract/inactive",
      contractClosed: "contract/closed",
    }),
  },
  filters: {
    formatDate(value: string): string {
      return formatDate(value, "full");
    },
    prepareMoney,
  },
})
class DocumentTable extends Vue {
  [x: string]: any;

  @Prop({ required: true }) readonly documents!: DocumentsByContract[];

  headers = [
    { text: "Документ", value: "type", width: "30%" },
    { text: "Аналитика", value: "analytics", width: "12.5%" },
    { text: "Тема", value: "theme", width: "5%" },
    {
      text: "Сумма с НДС",
      value: "sumWithTax",
      width: "15%",
      align: "end",
    },
    {
      text: "Сумма без НДС",
      value: "sumWithoutTax",
      width: "15%",
      align: "end",
    },
    {
      text: "К оплате",
      value: "sumToPaid",
      width: "15%",
      align: "end",
    },
  ];

  docKeys = [
    ["тип", "номер", "дата"],
    "аналитика",
    "тема",
    "суммасндс",
    "суммабезндс",
    "остаток",
  ];

  humanDocTypeByValue = {
    СФ: "Реализация",
    ПЛАТ: "Входящий платеж",
    ПКО: "Приходный ордер",
  };

  selectedAll = false;
  selectedRows: boolean[] = [];

  // Свойства, использующиеся в элементах шапки таблицы
  // (прим: расположены по порядку элементов).
  dates: string[] = [];

  docTypesBySenderStatus = ["Исходящие", "Входящие"];
  currentDocTypeBySenderStatusIndex = 0;

  docTypesByPaymentStatus = ["Все документы", "Неоплаченные"];
  currentDocTypeByPaymentStatusIndex = 1;

  public get usePayment(): boolean {
    const { appEnvironment } = this;

    return !!(appEnvironment.constants && appEnvironment.constants["ЛКЮЛОПЛАТА"]) && !this.contractClosed && !this.contractInactive;
  }

  /**
   * Вычисляемое свойство, значением которого является объект. Ключами такого
   * объекта являются значения свойств value объекта headers, а значениями - строки,
   * использующиеся в качестве css-классов td-элементов таблицы, что позволяет
   * определять стили для отдельного столбца. Например, для ячейки, расположенной
   * в столбце с заголовком "к оплате", будет создана следующая строка:
   * "data-table__td data-table__td_header_sum-to-paid" (см. vue-dev-tools).
   */
  public get tdClassesByHeaders() {
    const headerValues = this.headers.map(({ value }) => value);
    const tdClasses: { [headerValue: string]: string } = {};

    return headerValues.reduce((acc, headerValue) => {
      const headerId = headerValue.replace("document", "");
      let counter = 0;

      const headerIdKebabCase = headerId.replace(/[A-Z]/g, (match) => {
        const result = match.toLowerCase();
        return counter++ ? `-${result}` : result;
      });

      return {
        ...acc,
        [headerValue]: `data-table__td data-table__td_header_${headerIdKebabCase}`,
      };
    }, tdClasses);
  }

  public get totalUsedDocKeys(): number {
    return !this.currentDocTypeBySenderStatusIndex ? this.docKeys.length : 4;
  }

  public get preparedHeaders() {
    return [
      ...this.headers.slice(0, this.totalUsedDocKeys),
      { text: "", value: "action", width: "7.5%" },
    ];
  }

  public get preparedDocuments() {
    const requiredKeys = this.docKeys.slice(0, this.totalUsedDocKeys);

    return this.documents.map((document) => {
      const docValues = requiredKeys.map((keyByTd) => {
        return Array.isArray(keyByTd)
          ? keyByTd.map((key) => document[key])
          : document[keyByTd];
      });

      // Пустая строка, как последний элемент массива позволяет использовать дропдаун
      // в каждой заключительной ячейке строки таблицы.
      return [...docValues, ""];
    });
  }

  public get currentDocTypeBySenderStatus(): string {
    return this.docTypesBySenderStatus[this.currentDocTypeBySenderStatusIndex];
  }

  public get currentDocTypeByPaymentStatus(): string {
    return this.docTypesByPaymentStatus[
      this.currentDocTypeByPaymentStatusIndex
    ];
  }

  public get allowPayment(): boolean {
    const {
      currentDocTypeBySenderStatusIndex,
      currentDocTypeByPaymentStatusIndex,
      usePayment,
    } = this;

    return !!(
      usePayment &&
      !currentDocTypeBySenderStatusIndex &&
      currentDocTypeByPaymentStatusIndex
    );
  }

  public get runUpdate(): string {
    const {
      dates,
      currentDocTypeBySenderStatusIndex,
      currentDocTypeByPaymentStatusIndex,
    } = this;

    return [
      dates.join(" - "),
      currentDocTypeBySenderStatusIndex,
      currentDocTypeByPaymentStatusIndex,
    ].join(" | ");
  }

  public get docsLength(): number {
    return this.documents.length;
  }

  public get runSelect(): string {
    return this.selectedRows.join(" ");
  }

  public get totalSelectedRows(): number {
    return this.selectedRows.filter((state) => state).length;
  }

  public get selectedRowsSum(): number {
    const { documents, selectedRows } = this;

    return documents.reduce(
      (acc, document, i) => (selectedRows[i] ? acc + document["остаток"] : acc),
      0
    );
  }

  private openPaymentsPage(): void {
    const { documents, selectedRows } = this;
    const docs = documents.filter((document, i) => (selectedRows[i])).map((item) => {
      return {
        'Счет': item['документ'],
        'Сумма': item['остаток'],
      }
    });
    this.$router.push({ name: "payments", params: { amount: this.selectedRowsSum.toString(), docs: JSON.stringify(docs) } });
  }

  @Emit()
  public emitUpdateEvt() {
    const [from, to] = this.dates;

    this.$emit("update:docsProps", {
      contractId: this.contractId,
      type: this.currentDocTypeBySenderStatusIndex,
      noPayment: this.currentDocTypeByPaymentStatusIndex,
      from,
      to,
    });
  }

  @Emit()
  public propagateDocumentEvt(evtName: string, index: number) {
    this.$emit(evtName, this.documents[index]["документ"]);
  }

  /**
   * Использовать значение вычисляемого свойства runUpdate в качестве сигнала
   * для запуска события "update:docsProps". Такой vue-трюк позволяет одновременно
   * контролировать значения нескольких свойств состояния.
   */
  @Watch("runUpdate")
  public runUpdateChanged() {
    this.emitUpdateEvt();
  }

  @Watch("docsLength")
  public docsLengthChanged() {
    this.selectedRows = Array(this.docsLength).fill(false);
  }

  @Watch("runSelect")
  public runSelectChanged() {
    this.selectedAll = !this.selectedRows.some((isSelected) => !isSelected);
  }
}

export default DocumentTable;
